import React from 'react'
import Form from '../compenents/Contact/Form'
const Contact = () => {
  return (
    <>
        <Form/>
    </>
  )
}

export default Contact